@tailwind base;
@tailwind utilities;
@tailwind components;

.carousel .thumb img {
    width: 100% !important;
    height: 100% !important;
}

.carousel .slide img {
    max-height: 500px;  /* change this to whatever you want */
    width: auto;
}

.wrapper {
    width: 25px;
    height: 25px;
    position: fixed;
    bottom: 2%;
    right: 2%;
    z-index: 20;
}

.container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
}

.item {
    width: inherit;
    height: inherit;
    margin: auto;
    position: relative;
    transform-origin: 50% 100%;
}

.item::before {
    content: "";
    width: 200%;
    height: 200%;
    background-color: rgb(124 58 237);
    position: absolute;
    left: -50%;
    border-radius: 40%;
    animation: anim 12s linear infinite;
}

.item::after {
    content: "";
    width: 204%;
    height: 204%;
    background-color: rgb(79, 37, 152);
    position: absolute;
    left: -52%;
    border-radius: 40%;
    animation: anim 12s linear infinite;
    animation-delay: 0.5s;
}

@keyframes anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}